import React from "react";
import ContactModal from "./Contact/ContactModal";

const Contact = () => {
  const [openContactModal, setOpenContactModal] = React.useState(false);
  const handleOpenContactModal = () => setOpenContactModal(true);
  const handleCloseContactModal = () => setOpenContactModal(false);

  return (
    <div className="relative contact">
      <div id="container" className="flex flex-col items-center">
        <p className="mb-3 text-2xl font-medium text-amber-800">
          Start COOPERATE BUSSINESS
        </p>
        <h2 className="mb-3 text-5xl font-extrabold">Contact With Us</h2>
        <p className="w-2/5 mb-3 text-center">
          Contact us today for free support, consultation on equipment, and the
          best solutions to suit the size and budget of your business.
        </p>
        <button
          className="btn btn-secondary"
          data-aos="fade-up"
          data-aos-delay="300"
          onClick={handleOpenContactModal}
        >
          Contact With Us
        </button>
        <ContactModal
          open={openContactModal}
          onClose={handleCloseContactModal}
        />
      </div>
      <div className="absolute pic1 top-6">
        <img src={require("../Assets/img/rfid3.png")} alt="" />
      </div>
      <div className="absolute bottom-0 right-0 pic2">
        <img src={require("../Assets/img/Iphones.png")} alt="" />
      </div>
    </div>
  );
};

export default Contact;
