import React from "react";
import { partnersData } from "../data";

const Partners = () => {
  return (
    <div className="partners">
      <h1 className="pt-16 text-center text-white mx-7 xxs:pt-24 xs:pt-28 sm:pt-32 responsive-title">
        Our strategic <mark className="text-[#edae1e] bg-transparent">partners</mark>
      </h1>
      <div className="grid grid-cols-2 gap-3 mx-5 mt-16 xs:grid-cols-3 grid-rows-7 md:grid-rows-6 lg:grid-rows-5 xl:grid-rows-4 xxs:mx-7 xs:mx-16 sm:mx-24 md:mx-32 lg:mx-40 md:grid-cols-4 lg:grid-cols-5 xl:grid-cols-6 list">
      {partnersData.map((item) => 
        <div className="item">
          <img
            src={item.image}
            alt=""
          />
        </div>
      )};
      </div>
    </div>
  );
};

export default Partners;
