import React from "react";

function Hexagon(props) {
  switch (props.sizeVariant) {
    case "sm":
      return <HexagonSizeSM {...props} />;
    case "md":
      return <HexagonSizeMD {...props} />;
    case "lg":
      return <HexagonSizeLG {...props} />;
    case "xl":
      return <HexagonSizeXL {...props} />;
    default:
      return <HexagonSizeXL {...props} />;
  }
}

export default Hexagon;

export function HexagonSizeXL(props) {
  return (
    <div className="w-[520px] h-[600px]">
      <div className="absolute">
        <div>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="520"
            height="600"
            viewBox="0 0 519.6152422706632 600"
          >
            <path
              fill="#FFC540"
              d="M259.8076211353316 0L519.6152422706632 150L519.6152422706632 450L259.8076211353316 600L0 450L0 150Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="absolute flex items-center justify-center w-[inherit] h-[inherit]">
        <div className="w-[425px] h-[325px] flex items-center justify-center text-clip overflow-hidden">
          {props.text}
        </div>
      </div>
    </div>
  );
}

// Generate Hexagon with length 225px
// Props: text (receive a line of text and display in hexagon, overflow texts will be hidden and display "..." instead)
//        image (receive src image input and display, image won't display if input is null or error)
export function HexagonSizeLG(props) {
  return (
    <div>
      <div className={`w-[390px] h-[450px] ${props.className}`}>
        <div className="absolute">
          {props.image ? (
            <div>
              <svg
                className=""
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="390"
                height="450"
                viewBox="0 0 389.71143170299734 450"
              >
                <clipPath id="cp">
                  <path
                    fill="#FFC540"
                    d="M194.85571585149867 0L389.71143170299734 112.5L389.71143170299734 337.5L194.85571585149867 450L0 337.5L0 112.5Z"
                  ></path>
                </clipPath>
                <image
                  className="bg-no-repeat object-cover w-[700px] h-[450px]"
                  clipPath="url(#cp)"
                  href={props.image}
                  alt="display image"
                  x="-50%"
                  width="100%"
                  height="100%"
                />
              </svg>
            </div>
          ) : (
            <div>
              <svg
                className=""
                version="1.1"
                xmlns="http://www.w3.org/2000/svg"
                width="390"
                height="450"
                viewBox="0 0 389.71143170299734 450"
              >
                <path
                  fill="#FFC540"
                  d="M194.85571585149867 0L389.71143170299734 112.5L389.71143170299734 337.5L194.85571585149867 450L0 337.5L0 112.5Z"
                ></path>
              </svg>
            </div>
          )}
        </div>
        <div className="absolute flex items-center justify-center w-[inherit] h-[inherit]">
          <div className="w-[335px] h-[225px] flex items-center justify-center text-clip overflow-hidden">
            {props.text}
          </div>
        </div>
      </div>
    </div>
  );
}

// Generate Hexagon with length 150px
// Props: text (receive a line of text and display in hexagon, overflow texts will be hidden and display "..." instead)
export function HexagonSizeMD(props) {
  return (
    <div
      className={`w-[174px] h-[200px] ${props.className}`}
      style={props.style}
    >
      <div className="absolute">
        <div>
          <svg
            className=""
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="174"
            height="200"
            viewBox="0 0 173.20508075688772 200"
          >
            <path
              fill={props.color !== undefined ? props.color : "#FFC540"}
              d="M86.60254037844386 0L173.20508075688772 50L173.20508075688772 150L86.60254037844386 200L0 150L0 50Z"
            ></path>
          </svg>
        </div>
      </div>
      <div className="absolute flex items-center justify-center w-[inherit] h-[inherit]">
        <div className="w-[100px] h-[125px] flex items-center justify-center text-clip overflow-hidden">
          {props.text}
        </div>
      </div>
    </div>
  );
}

// Hexagon with length 75px
export function HexagonSizeSM(props) {
  return (
    <div
      className={`w-[130px] h-[150px] ${props.className}`}
      style={props.style}
    >
      <div className="absolute">
        <div className={``}>
          <svg
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
            width="130"
            height="150"
            viewBox="0 0 129.9038105676658 150"
            fill="#FFC540"
          >
            <path d="M64.9519052838329 0L129.9038105676658 37.5L129.9038105676658 112.5L64.9519052838329 150L0 112.5L0 37.5Z"></path>
          </svg>
        </div>
      </div>
      <div className="absolute flex items-center justify-center w-[inherit] h-[inherit]">
        <div className="w-[80px] h-[100px] flex items-center justify-center text-clip overflow-hidden">
          {props.text}
        </div>
      </div>
    </div>
  );
}
