import React, { Suspense } from "react";
import Aos from "aos";
// import "./App.css";
import "aos/dist/aos.css";
import "./App.scss";
import BestChoice from "./Components/BestChoice";
import Footer from "./Components/Footer";
import Contact from "./Components/Contact";
import Partners from "./Components/Partners";

const Header = React.lazy(() => import("./Components/Header"));
// const Slider = React.lazy(() => import("./Components/Slider"));
const Hero = React.lazy(() => import("./Components/Hero"));
// const About = React.lazy(() => import("./Components/About"));
const Services = React.lazy(() => import("./Components/Services"));
// const Carousel = React.lazy(() => import("./Components/Carousel"));
// const SectionFocusScroll = React.lazy(() => import("./Components/Scroll/SectionFocusScroll"));
const Project = React.lazy(() => import("./Components/Projects"));

const App = () => {
  const [ setFocusTo] = React.useState(0)

  const handleSetFocusSection = (input) => {
    setFocusTo(input);
  };

  React.useEffect(() => {
    Aos.init({
      duration: 1800,
      offset: 0,
    });
  }, []);

  return (
    <div className="relative">
      <div className="overflow-hidden">
      <Suspense fallback={<div></div>}>
        <Header onChangeFocusSection={handleSetFocusSection} />
        <Suspense fallback={<div></div>}>
          <Hero />
          <Services />
          <BestChoice />
          <Partners />
          <Project />
          <Contact />
          
          <Footer />
          {/* <SectionFocusScroll
            extraScrollHeight={100}
            disableScrollTime={800}
            focusTo={focusTo}
          >
            <Slider />
            <Hero />
            <About />
            <Services />
            <Projects />
            <Carousel />
            <div>
              <Contact />
              <Footer />
            </div>
          </SectionFocusScroll> */}
        </Suspense>
      </Suspense>
      </div>
    </div>
  );
};

export default App;
