import React, { useState } from 'react';
import { Box, Fade, Modal, TextField, Backdrop } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import CloseIcon from '@mui/icons-material/Close';
import Hexagon from '../Hexagon';
import LogoImage from '../../Assets/img/logo-image.webp';

function ContactModal(props) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [message, setMessage] = useState('');
  const [errors, setErrors] = useState({});

  const modalContentStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    overflow: 'hidden',
    bgcolor: 'white',
    border: '2px solid lightgrey',
    borderRadius: '12px',
    boxShadow: 24,
    p: 4,
    '@media (max-width: 800px)' : {
      width: '100%',
      minHeight: '700px',
      height: '100%',
      borderRadius: '0px',
    }
  };

  const CustomHelperText = {
    style: {
      backgroundColor: "#efaf03",
      color: "white",
      margin: '0 3px',
      padding: '0 12px',
      width: 'fit-content',
      borderBottomRightRadius: '4px',
      borderBottomLeftRadius: '4px',
      fontSize: '13px',
    }
  }

  const validateForm = () => {
    const errors = {};

    // Validate name
    if (name.trim() === '') {
      errors.name = 'Name is required';
    }

    // Validate email
    if (email.trim() === '') {
      errors.email = 'Email is required';
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = 'Invalid email address';
    }

    // Validate phone number
    if (phone.trim() === '') {
      errors.phone = 'Phone number is required';
    } else if (!/^\d{10}$/.test(phone)) {
      errors.phone = 'Invalid phone number';
    }

    // Validate message
    if (message.trim() === '') {
      errors.message = 'Message is required';
    }

    setErrors(errors);

    return Object.keys(errors).length === 0; // Return true if there are no errors
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      // Perform submit logic here
      console.log('Form is valid. Submitting...');
    } else {
      console.log('Form validation failed');
    }
  };

  return (
    <div>
      <Modal
        keepMounted
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={props.open}>
          <Box sx={modalContentStyle}>
            <div className="absolute left-[10px] bottom-[10px] opacity-[.15] translate-x-[-30%] translate-y-[30%]">
              <Hexagon sizeVariant="lg" />
            </div>
            <div className="absolute top-[10px] right-[10px] opacity-[.15] translate-x-[45%] translate-y-[-30%]">
              <Hexagon sizeVariant="sm" />
            </div>
            <div className="absolute w-[50%] h-[300px] opacity-[.1] bottom-0 right-0 translate-x-[-25%] translate-y-[-25%]">
              <img src={LogoImage} alt="Logo" width={`150px`} height={`150px`} />
            </div>
            <div className="absolute right-[18px] top-[30px]" onClick={props.onClose}>
              <CloseIcon
                sx={{
                  color: 'black',
                  fontSize: '30px',
                  '&:hover': {
                    color: '#eeaf1e',
                    cursor: 'pointer',
                  },
                }}
              />
            </div>
            <div className="mb-[35px] align-center">
              <h4 className="text-[40px] font-bold leading-[42px] mb-4">Contact us</h4>
              <div>We would love to hear from you</div>
            </div>

            <div>
              <TextField
                fullWidth
                id="contact_name"
                label="Name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                sx={{ mb: '12px' }}
                color={errors.name ? 'warning' : undefined}
                focused={Boolean(errors.name)}
                FormHelperTextProps={CustomHelperText}
                helperText={errors.name || ''}
              />
                <TextField
                  fullWidth
                  id="contact_email"
                  label="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  sx={{ mb: '12px' }}
                  color={errors.email ? 'warning' : undefined}
                  focused={Boolean(errors.email)}
                  FormHelperTextProps={CustomHelperText}
                  helperText={errors.email || ''}
                />
                <TextField
                  fullWidth
                  id="contact_phone"
                  label="Phone"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  sx={{ mb: '12px' }}
                  color={errors.phone ? 'warning' : undefined}
                  focused={Boolean(errors.phone)}
                  FormHelperTextProps={CustomHelperText}
                  helperText={errors.phone || ''}
                />
                <TextField
                  fullWidth
                  id="contact_message"
                  label="Message"
                  multiline
                  rows={6}
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  sx={{ mb: '12px' }}
                  color={errors.message ? 'warning' : undefined}
                  focused={Boolean(errors.message)}
                  FormHelperTextProps={CustomHelperText}
                  helperText={errors.message || ''}
                />
            </div>

            <div className="flex justify-center">
              <button className="btn-sm btn-secondary" onClick={handleSubmit}>
                Submit<SendIcon sx={{ fontSize: '1.125rem', ml: '8px' }} />
              </button>
            </div>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
}

export default ContactModal;
