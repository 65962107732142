import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  return (
    <div
      className="text-center text-white footer text-lg-start"
      style={{ backgroundColor: "#45526e" }}
    >
      <div className="w-full">
        <div className="flex flex-row pr-2.5">
          <div className="mx-auto mt-3 col-md-3 col-lg-3 col-xl-3">
            <img src={require("../Assets/img/logo-beetech.png")} alt="" />
          </div>
          <hr className="clearfix w-100 d-md-none" />
          <div className="mx-auto mt-3 col-md-2 col-lg-2 col-xl-2">
            <h6 className="mb-4 text-white text-uppercase font-weight-bold">
              Products
            </h6>
            <p>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                Pricing
              </a>
            </p>
            <p>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                Overview
              </a>
            </p>
            <p>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                Accessibility
              </a>
            </p>
            <p>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="text-white"
              >
                Browse
              </a>
            </p>
          </div>
          <hr className="clearfix w-100 d-md-none" />
          <div className="mx-auto mt-3 col-md-3 col-lg-2 col-xl-2">
            <h6 className="mb-4 text-white text-uppercase font-weight-bold">
              Solutions
            </h6>
            <p>
              <a href="https://" className="text-white">
                Brainstorming
              </a>
            </p>
            <p>
              <a href="https://" className="text-white">
                Ideation
              </a>
            </p>
            <p>
              <a href="https://" className="text-white">
                Wireframing
              </a>
            </p>
            <p>
              <a href="https://" className="text-white">
                Research
              </a>
            </p>
          </div>
          <hr className="clearfix w-100 d-md-none" />
          <div className="mx-auto mt-3 col-md-4 col-lg-3 col-xl-3">
            <h6 className="mb-4 text-white text-uppercase font-weight-bold">
              Company
            </h6>
            <p>
              <i className="mr-3 fas fa-home" /> About
            </p>
            <p>
              <i className="mr-3 fas fa-envelope" /> Press
            </p>
            <p>
              <i className="mr-3 fas fa-phone" /> Event
            </p>
            <p>
              <i className="mr-3 fas fa-print" /> Request demo
            </p>
          </div>
        </div>
        <hr className="my-3" />
        <div className="p-3 pt-0">
          <div className="row d-flex align-items-center">
            <div className="text-center col-md-7 col-lg-8 text-md-start">
              <div className="p-3">
                © 2023 BeeTech.
                <span className="text-white">All rights reserved.</span>
              </div>
            </div>
            <div className="text-center col-md-5 col-lg-4 ml-lg-0 text-md-end">
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="m-1 btn btn-outline-light btn-floating"
                role="button"
              >
                <FacebookIcon />
              </a>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="m-1 btn btn-outline-light btn-floating"
                role="button"
              >
                <TwitterIcon />
              </a>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="m-1 btn btn-outline-light btn-floating"
                role="button"
              >
                <LinkedInIcon />
              </a>
              <a
                href="https://"
                target="_blank"
                rel="noreferrer"
                className="m-1 btn btn-outline-light btn-floating"
                role="button"
              >
                <InstagramIcon />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
