// import icons
import {
  FaYoutube,
  FaFacebookF,
  FaTwitter,
  FaLinkedinIn,
} from "react-icons/fa";
import { BsChatDotsFill } from "react-icons/bs";

// import images
import BeetechLogo from "../src/Assets/img/logo180.webp";
import Feature1Img from "../src/Assets/img/features/feature1.webp";
import Feature2Img from "../src/Assets/img/features/feature2.webp";
import Feature3Img from "../src/Assets/img/features/feature3.webp";
import Feature4Img from "../src/Assets/img/features/feature4.webp";
import Feature1BgImg from "../src/Assets/img/features/feature1-bg.webp";
import Feature2BgImg from "../src/Assets/img/features/feature2-bg.webp";
import Feature3BgImg from "../src/Assets/img/features/feature3-bg.webp";
import Feature4BgImg from "../src/Assets/img/features/feature4-bg.webp";

import friendAvatar1 from "../src/Assets/img/profileImage/friend-avatar1.webp";
import friendAvatar2 from "../src/Assets/img/profileImage/friend-avatar2.webp";
import friendAvatar3 from "../src/Assets/img/profileImage/friend-avatar3.webp";
import friendAvatar4 from "../src/Assets/img/profileImage/friend-avatar4.webp";
import friendAvatar5 from "../src/Assets/img/profileImage/friend-avatar5.webp";
import friendAvatar6 from "../src/Assets/img/profileImage/friend-avatar6.webp";
import friendAvatar7 from "../src/Assets/img/profileImage/friend-avatar7.webp";
import friendAvatar8 from "../src/Assets/img/profileImage/friend-avatar8.webp";
import friendAvatar9 from "../src/Assets/img/profileImage/friend-avatar9.webp";

import RetailManagementImage from "../src/Assets/img/projects/retail-management.webp";
import AssetsManagementImage from "../src/Assets/img/projects/smart-warehouse-management.webp";
import RFIDTunnel from "../src/Assets/img/projects/leading-star-750x563.webp";
import BelFactory from "../src/Assets/img/projects/overview-750x447.webp";
import Logoplaste from "../src/Assets/img/projects/logoplaste-1-750x708.webp";
import CravenA from "../src/Assets/img/projects/Craven_A.webp";

import CompanyMission from "../src/Assets/img/CompanyMission.webp";
import CompanyVision from "../src/Assets/img/CompanyVision.webp";

import { ImBarcode } from "react-icons/im";
import { GiBrain } from "react-icons/gi";
import { BiRfid } from "react-icons/bi";
import { BsCodeSlash } from "react-icons/bs";
import { LuCombine } from "react-icons/lu";
import { MdBarcodeReader } from "react-icons/md";

import GroupsIcon from '@mui/icons-material/Groups';
import DevicesIcon from '@mui/icons-material/Devices';
import VerifiedIcon from '@mui/icons-material/Verified';
import LightbulbIcon from '@mui/icons-material/Lightbulb';

import partnerImage1 from "../src/Assets/img/partners/Link → 1.impinj.png.png";
import partnerImage2 from "../src/Assets/img/partners/Link → 2.zebra_.png.png";
import partnerImage3 from "../src/Assets/img/partners/Link → 3.hid_.png.png";
import partnerImage4 from "../src/Assets/img/partners/Link → 4.smartrac.jpg.png";
import partnerImage5 from "../src/Assets/img/partners/Link → 5.honeywell.png.png";
import partnerImage6 from "../src/Assets/img/partners/Link → 6.Times-7.jpg.png";
import partnerImage7 from "../src/Assets/img/partners/Link → 7.Unitech.png.png";
import partnerImage8 from "../src/Assets/img/partners/Link → 8.Gao_.jpg.png";
import partnerImage9 from "../src/Assets/img/partners/Link → 9.Troi_.jpg.png";
import partnerImage10 from "../src/Assets/img/partners/Link → 10.Omni-ID.jpg.png";
import partnerImage11 from "../src/Assets/img/partners/Link → confidex.jpg.png";
import partnerImage12 from "../src/Assets/img/partners/Link → datalogic.jpg.png";
import partnerImage13 from "../src/Assets/img/partners/Link → denso.png.png";
import partnerImage14 from "../src/Assets/img/partners/Link → keonn.jpg.png";
import partnerImage15 from "../src/Assets/img/partners/Link → sato.jpg.png";
import partnerImage16 from "../src/Assets/img/partners/Link → sensthys.jpg.png";
import partnerImage17 from "../src/Assets/img/partners/Link → thingmagic.jpg.png";
import partnerImage18 from "../src/Assets/img/partners/Link → 9.printronix.png.png";

/* 
Currently navigationData.href is prevented by event.preventDefault() 
action in Nav.jsx as it work like a section focus. To use href, 
remove event.preventDefault() line and delete this comment
*/

export const hero = {
  headline: "We make things",
  highlight: "smarter",
  subtitle:
    "We specialize in providing customized products and services customized technology. With a team of experienced staff trained and working in famous corporations.",
};

export const servicesData = {
  headline: "We have your",
  highlight: "solution!",
  subtitle: "With 13 years of experience in the IT field – implementing many large projects, professional and qualified staff – are experts who have worked at famous corporations such as StrataCom, Cisco, AMCC, Global Cyber Soft, NecSoft IESVN, … Besides, we are the leading strategic partner of major technology companies in the world.",
  services: [
    { icon: <ImBarcode />, title: "Barcode" },
    { icon: <GiBrain />, title: "IOT&AI" },
    { icon: <BsCodeSlash />, title: "Software Developer" },
    { icon: <BiRfid />, title: "RFID" },
    { icon: <LuCombine />, title: "Integrated solution" },
    { icon: <MdBarcodeReader />, title: "IT Product & Solutions" },
  ],
};

export const bestChoiceData = {
  headline: "Why choosing",
  highlight: "us",
  subtitle: "The key to our success is that we embrace collaboration and demand that our strategists, designers and project managers work closely and directly with us.",
  reasons: [
    {icon: <GroupsIcon sx={{ fontSize: "32px", mr: "8px" }} />, title: "People", description: "With experienced staff, experts who work in famous corporations. We are confident to provide our customers with quality services."},
    {icon: <DevicesIcon sx={{ fontSize: "32px", mr: "8px" }} />, title: "Technology", description: "We are constantly researching, developing, wait in the front for technology, create a breakthrough, to give customers the best choice."},
    {icon: <VerifiedIcon sx={{ fontSize: "32px", mr: "8px" }} />, title: "Responsibility", description: "Our motto is to always put the prestige on the top, commitment and responsibility for all products and services we provide."},
    {icon: <LightbulbIcon sx={{ fontSize: "32px", mr: "8px" }} />, title: "Solution", description: "We will consult, design solutions that match your model, budget and requirements. To save costs, optimize and transparency in management."}]
}

export const partnersData = [
{image: partnerImage1},
{image: partnerImage2},
{image: partnerImage3},
{image: partnerImage4},
{image: partnerImage5},
{image: partnerImage6},
{image: partnerImage7},
{image: partnerImage8},
{image: partnerImage9},
{image: partnerImage10},
{image: partnerImage11},
{image: partnerImage12},
{image: partnerImage13},
{image: partnerImage14},
{image: partnerImage15},
{image: partnerImage16},
{image: partnerImage17},
{image: partnerImage18},
]

export const navigationData = [
  {
    name: "Home",
    href: "#",
    sectionOrder: 0,
  },
  {
    name: "About Us",
    href: "#",
    sectionOrder: 2,
  },
  {
    name: "Service",
    href: "#",
    sectionOrder: 3,
  },
  {
    name: "Projects",
    href: "#",
    sectionOrder: 4,
  },
  {
    name: "Contact",
    href: "#",
    sectionOrder: 6,
  },
];

export const aboutBeetechData = {
  primaryQuote:
    " We are committed to providing our customers with smart solutions, helping to more optimize in management - increasing profitability as well as a competitive advantage, business opportunities, and accompanying our customers - as well as local or intensive partners in each market.",
  secondaryQuote:
    "”Not Vietnamese companies do not have quality products. The world can do it, we can do it, even do better.”",
  beetechWorks:
    "Bee Tech was the first company in Vietnam to work on RFID. Specializing in consulting and providing leading technology solutions (AutoID; IoT, Al, and PL) in Southeast Asia. We want to change the Vietnamese people’s mindset in making technology is",
  humanResource:
    "With a team of experienced staff, experts have worked at famous corporations such as StrataCom, Cisco, AMCC, Altera, AMCC, XilinX, Renesas, Viettel, Gtel, CSC Viet Nam NEC, We have been affirming our pioneering role in RFID solutions in Vietnam. In these years, we have grown steadily, strong, and have built for ourselves an important place for business partners. Recognized by the market as the top provider of reliable solutions (especially affirms our pioneering role in RFID solutions in Vietnam).",
  vision: {
    image: CompanyVision,
    descriptions: [
      "Become a recognized, respected, and trusted global leader in the IT field",
      "Leading distributor of solutions and technologies (AutoID; IoT; Al and LM) in Southeast Asia",
      "Cooperate with research and development of enterprise solutions that meet require of markets in Southeast Asia with global technology companies.",
      "Develop an ecosystem of products and services to provide opportunities business and accompany with local or specialized partners in each brand.",
    ],
  },
  mission: {
    image: CompanyMission,
    descriptions: [
      "Develop general software solutions to help businesses have an intelligent and efficient operating system.",
      "Constantly researching and improving to bring to customers and partners quality products and services.",
      "Building a working environment to help develop maximum capacity, knowledge, and working efficiency of staff.",
      "Accompanying and co-operating to bring more opportunities and benefits to business partners.",
    ],
  },
};

export const servicesData2 = {
  title: "Some Services We Offer",
  subtitle:
    "With our app you can view the route of your order, from our local headquarters to the place where you are. Look for the app now!",
  list: [
    {
      image: Feature1Img,
      bgImage: Feature1BgImg,
      title: "RFID",
      description:
        "We create a combination of RFID technology application solutions in areas from Manufacturing - processing, logistics - transportation, traffic, retail, schools, healthcare, ... to support monitor, manage, store information about assets and people ... automatically and easily.",
      linkText: "Learn more",
      link: "/",
      delay: "400",
    },
    {
      image: Feature2Img,
      bgImage: Feature2BgImg,
      title: "IT Platforms Product & Solution",
      description:
        "Provide equipment (servers, workstations, storage devices, network equipment ..) IT service, cabling service, PA system, backup, and recovery solutions, think client, clustering.",
      linkText: "Learn more",
      link: "/",
      delay: "700",
    },
    {
      image: Feature3Img,
      bgImage: Feature3BgImg,
      title: "Barcode",
      description:
        "This is the method of encoding numeric characters and letters into 1D / 2D barcodes that allow bar code management (create, edit, print, paste, read, tracking, inventory) on goods to be managed quickly and conveniently with accuracy at low cost.",
      linkText: "Learn more",
      link: "/",
      delay: "1000",
    },
    {
      image: Feature4Img,
      bgImage: Feature4BgImg,
      title: "IoT & AI",
      description:
        "This is the method of encoding numeric characters and letters into 1D / 2D barcodes that allow bar code management (create, edit, print, paste, read, tracking, inventory) on goods to be managed quickly and conveniently with accuracy at low cost..",
      linkText: "Learn more",
      link: "/",
      delay: "1300",
    },
  ],
};

export const projectsData = [
  {
    image: AssetsManagementImage,
    category: "RFID & Barcode",
    title: "Asset management",
    date: "03/03/2024",
    preview:
      "One project for managing assets for FamilyMart involves developing a comprehensive asset management system to streamline operations and optimize resource allocation",
  },
  {
    image: RetailManagementImage,
    category: "Sale",
    title: "Retail management",
    date: "03/03/2024",
    preview:
      "The project aims to provide a comprehensive solution for managing various aspects of the retail business, including inventory, sales, customer information, and reporting.",
  },
  {
    image: RFIDTunnel,
    category: "RFID & Barcode",
    title: "RFID tunnel and convey system",
    date: "06/01/2021",
    preview:
      "Beetech provided RFID tunnel and convey system to check finished goods to finished goods warehouse.",
    href: "https://beetech.com.vn/en/project/rfid-barcode/rfid-tunnel-and-convey-system/",
  },
  {
    image: BelFactory,
    category: "Security",
    title: "BEL factory intruder prevention system",
    date: "02/02/2021",
    preview:
      "System real-time 24/365 days detect intruders as soon as a protected area is entered which based beam technology",
    href: "https://beetech.com.vn/en/project/others/he-thong-phong-chong-nguoi-la-xam-nhap-nha-may-bel/",
  },
  {
    image: Logoplaste,
    category: "RFID & Barcode",
    title: "Logoplaste - Smart Warehouse Management",
    date: "11/11/2020",
    preview:
      "provided 'factory intruder prevention system' real-time 24/365 days.",
    href: "https://beetech.com.vn/en/project/rfid-barcode/cung-cap-he-thong-quan-ly-cho-kho-nguyen-phu-lieu-tai-nha-may-logoplaste/",
  },
  {
    image: CravenA,
    category: "Software & website",
    title: "Craven A - Inventory Management",
    date: "10/11/2020",
    preview:
      "We provides the goods management system (export, import, inventory) of raw materials at packaging warehouses, yarn warehouses, and finishing product warehouses to apply RFID technology.",
    href: '"https://beetech.com.vn/en/project/software-website/ung-dung-cong-nghe-rfid-vao-quan-ly-kho-soi-tai-nha-may-thuoc-la-craven-a/',
  },
];

export const ctaData = {
  title: "Contact With Us",
  subtitle:
    "Contact us today for free support, consultation on equipment, and the best solutions to suit the size and budget of your business.",
  btnText1: "Learn more",
  btnText2: "Request Demo",
};

export const footerData = {
  logo: BeetechLogo,
  address: [
    "Head Office: 30 Street 18, Quarter 5, Hiep Binh Chanh Ward, Thu Duc Dist, HCMC Vietnam",
    "Ha Noi Branch: 30 Street 18, Quarter 5, Hiep Binh Chanh Ward, Thu Duc Dist, HCMC Vietnam",
  ],
  email: "info@beetech.com.vn",
  phone: "+84 (028) 66529295",
  list1: [
    {
      name: "beetech.com.vn",
      href: "https://beetech.com.vn/en/",
    },
    {
      name: "iotsolution.com.vn",
      href: "http://iotsolution.com.vn",
    },
    {
      name: "rfidsolution.com.vn",
      href: "http://rfidsolution.com.vn",
    },
    {
      name: "barcodesolution.com.vn",
      href: "http://barcodesolution.com.vn",
    },
    {
      name: "splendidtechnology.com.vn",
      href: "http://splendidtechnology.com.vn",
    },
  ],
  list2: [
    {
      name: "Support",
      href: "#",
    },
    {
      name: "Sign Up",
      href: "#",
    },
    {
      name: "Guide",
      href: "#",
    },
    {
      name: "Reports",
      href: "#",
    },
    {
      name: "Q & A",
      href: "#",
    },
  ],
  socialList: [
    {
      icon: <FaYoutube />,
      href: "https://www.youtube.com/channel/UCYYQOxm0VCwxOV5ImAe1Klw",
    },
    {
      icon: <FaFacebookF />,
      href: "https://www.facebook.com/rfidsolutionvietnam",
    },
    {
      icon: <FaTwitter />,
      href: "https://twitter.com/Beetech15",
    },
    {
      icon: <FaLinkedinIn />,
      href: "https://www.linkedin.com/in/beetech/",
    },
  ],
};

export const aboutUsInfo = [
  {
    img: friendAvatar1,
    name: "Kiên Trần",
    title: "Frontend Developer, Data Analyst",
    description:
      "My name is Barry McKockiner, I'm the fastest deadline runner alive. ",
  },
  {
    img: friendAvatar2,
    name: "Nguyễn Đức Thành",
    title: "Data Scientist, System Administrator",
    description:
      "Don't confuse me for someone who obsess with busting their brothers and stop calling me Căng Đét",
  },
  {
    img: friendAvatar3,
    name: "Remy Sharp",
    title: "Full Stack developer, QA Tester, Computer Technician",
    description:
      "I am an adventurous and spontaneous soul, always seeking new experiences and challenges. My curious nature leads me to explore the unknown and push my limits. With a creative mind and a passion for storytelling, I find joy in expressing myself through writing and connecting with others on a deep level.",
  },
  {
    img: friendAvatar4,
    name: "Mike Hunt",
    title: "Backend developer, Network Administrator",
    description: "Yo, you know the name ? If you don't, I will let you know",
  },
  {
    img: friendAvatar5,
    name: "Jenny Taila",
    title: "Chief Information Officer",
    description:
      "As a compassionate and empathetic individual, I prioritize understanding and supporting others. I thrive in nurturing environments where I can bring people together and create harmonious relationships. With a keen eye for detail and a love for organization, I excel in planning and executing projects with precision and efficiency.",
  },
  {
    img: friendAvatar6,
    name: "Moe Lester",
    title: "Database Administrator",
    description: "Don't call police on me",
  },
  {
    img: friendAvatar7,
    name: "Dixie Normus",
    title: "Information Security Analyst",
    description:
      "Mike's is small, If you want to know what's a true champion is, you know who to call. ",
  },
  {
    img: friendAvatar8,
    name: "Sang Trần",
    title: "Full Stack developer, QA Tester, Computer Technician",
    description:
      "With a zest for life and a positive outlook, I radiate joy and enthusiasm in everything I do. I am a social butterfly, effortlessly connecting with people from all walks of life and creating meaningful relationships. As a lifelong learner, I embrace change and embrace new opportunities for growth and self-discovery.",
  },
  {
    img: friendAvatar9,
    name: "Vinny Shine",
    title: "Data Scientist, System Administrator",
    description:
      "I am a dedicated and disciplined individual, always striving for excellence in my pursuits. With a meticulous approach and a strong work ethic, I consistently achieve my goals. I am an avid problem-solver.",
  },
];

export const copyrightData = {
  text: "© by Beetech, 2023. All rights reserved.",
  icon: <BsChatDotsFill />,
};
