import { bestChoiceData } from "../data";
import Hexagon from "./Hexagon";

function BestChoice() {
  const { headline, subtitle, highlight, reasons } = bestChoiceData;
  return (
    <section className="h-auto bg-[#EEF3F1] w-full reponsiveWrapper pt-[70px] sm:pt-[160px] pb-[80px] lg:pt-[200px] relative">
      {/* Background Decoration */}
      <div className="absolute h-[780px] w-full">
        {/* hegagon group top-left */}
        <div className="absolute top-[-260px] xxs:top-[-290px] xs:top-[-325px] sm:top-[-360px] left-[-116px] xxs:left-[-98px] xs:left-[-80px] sm:left-[-45px] md:left-auto scale-[.4] xxs:scale-[.5] xs:scale-[.6] sm:scale-75 md:scale-100">
          <div
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <Hexagon sizeVariant="lg" />
          </div>
        </div>
        <div className="responsive-scale">
          <div
            className="absolute top-[140px] sm:top-[100px] left-[-190px] sm:left-[-110px] md:left-auto"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <Hexagon sizeVariant="md" />
          </div>
        </div>

        {/* hegagon group right */}
        <div className="">
          <div className="hidden lg:block absolute top-[-340px] right-[200px] scale-75">
            <div
              data-aos="fade-left"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              {" "}
              <Hexagon sizeVariant="lg" />
            </div>
          </div>
        </div>

        <div className="responsive-scale">
          <div
            className="absolute top-[-40px] right-[-375px] xxs:right-[-350px] xs:right-[-290px] sm:right-[-230px] md:right-[-160px]"
            data-aos="fade-left"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <Hexagon sizeVariant="xl" />
          </div>
        </div>

        <div className="absolute bottom-[230px] right-[296px] scale-50">
          <div
            data-aos="fade-up-left"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <Hexagon sizeVariant="sm" />
          </div>
        </div>

        <div className="absolute bottom-[130px] right-[230px] responsive-scale">
          <div
            data-aos="fade-up-left"
            data-aos-duration="1200"
            data-aos-delay="200"
          >
            <Hexagon sizeVariant="sm" />
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="">
        {/* headline */}
        <h1
          className="responsive-title mx-4 xs:mx-5 text-[black] font-extrabold font-['DM_Sans'] text-center"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {headline}
          <mark className="text-white nl:text-[#edae1e] bg-transparent">
            {" " + highlight}
          </mark>
        </h1>

        {/* Description */}
        <div
          className="text-[16px] xs:text-[18px] sm:text-[20px] lg:text-[22px] text-[black] mt-[32px] font-['DM_Sans'] font-semibold text-center max-w-[1000px] mx-4 xs:mx-5 sm:mx-7 lg:mx-auto"
          data-aos="fade-up"
          data-aos-duration="1000"
        >
          {subtitle}
        </div>

        <div className="flex flex-wrap justify-center gap-5 font-['DM_Sans'] mt-[56px]">
          {reasons.map((item, index) => (
            <div
              key={index}
              className="bg-white rounded-lg w-[300px] xs:w-[200px] md:w-[300px] mx-3 xs:mx-0 p-[16px] xs:p-[20px] sm:p-[24px] md:p-[28px] z-10"
              data-aos="fade"
              data-aos-duration="1000"
              data-aos-delay={200 * index}
            >
              <div className="text-[#edae1e] font-semibold text-[16px] sm:text-[17px] md:text-[30px] mb-2">
                {item.icon}
                {item.title}
              </div>
              <div className="text-[12px] xs:text-[13px] sm:text-[15px] md:text-[18px]">
                {item.description}
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}

export default BestChoice;
